h1 {
    margin: 0;
    padding: 0;
  }
  html, body, .app {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100vh;
  }
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
  }
  .popup_inner {
    position: absolute;
    z-Index: 0;
    left: 30%;
    right: 30%;
    top: 30%;
    bottom: 30%;
    margin: auto;
    background: white;
  }

  .popup_inner_mobile {
    position: absolute;
    z-Index: 0;
    top: 20%;
    width: 90%;
    height: 50%;
    margin: auto;
    background: white;
  }

  .popup_inner_mobile_backtest {
    position: absolute;
    z-Index: 0;
    top: 20%;
    left:25%;
    width: 50%;
    height: 50%;
    margin: auto;
    background: white;
  }

  .popup_login {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
  }

  .popup_inner_mobile_login {
    position: absolute;
    z-Index: 0;
    top: 20%;
    width: 80%;
    right: 10%;
    left: 10%;
    height: 50%;
    margin: auto;
    background: white;
  }