.content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
  }
  
  .content.show {
    max-height: 1000px; /* Un valor muy grande para permitir expansión */
    opacity: 1;
  }