/* CookieConsentPopup.css */


  /* Estilos para los enlaces */
  a {
    font-weight: 600;
    color: #0078d4; /* Color azul típico de los enlaces de Microsoft */
    text-decoration: underline !important; /* Usar !important para forzar la aplicación */
    cursor: pointer; /* Cambiar el cursor al pasar sobre los enlaces */
  }

  .cookie-banner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  

  .input_checkbox {
   
    margin-right: 10px;
    margin-left: -10px;
    font-size: 14px;
  }

  .cookie-pref-title-sup {
   
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
  }

.cookie-consent-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    z-index: 1000;
  }
  
  .cookie-text {
    font-size: 13px;
    margin-bottom: 20px;
  }

  .cookie-pref-text {
    font-size: 13px;
    margin-bottom: 0px;
  }
  
  .cookie-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .cookie-pref-title {
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .accept-button,
  .reject-button {
    
      background-color:black;
      color: white;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      margin-right: 10px;
      border-radius: 5px;
      font-size: 13px; 
  }

  .accept-button-mbl {
    
      background-color:black;
      color: white;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      margin-left: 20px;
      border-radius: 5px;
      font-size: 13px; 
  }

  .configure-button {
    background-color: white; /* Fondo blanco */
    border: 2px solid black; /* Borde verde */
    color: black; /* Texto verde */
    padding: 2px 20px; /* Espacio alrededor del texto */
    text-align: center; /* Centrar el texto horizontalmente */
    text-decoration: none; /* Eliminar subrayado del enlace */
    display: inline-block; /* Mostrar como elemento en línea */
    font-size: 13px; /* Tamaño de fuente */
    cursor: pointer; /* Cambiar el cursor al pasar sobre el botón */
    border-radius: 5px; /* Bordes redondeados */
  }

  .configure-button-popup {
    background-color: white; /* Fondo blanco */
    border: 2px solid ; /* Borde verde */
    color: black; /* Texto verde */
    padding: 2px 20px; /* Espacio alrededor del texto */
    text-align: center; /* Centrar el texto horizontalmente */
    text-decoration: none; /* Eliminar subrayado del enlace */
    display: inline-block; /* Mostrar como elemento en línea */
    font-size: 13px; /* Tamaño de fuente */
    cursor: pointer; /* Cambiar el cursor al pasar sobre el botón */
    border-radius: 5px; /* Bordes redondeados */
    margin-right:12px
  }
  
  .accept-button:hover,
  .reject-button:hover {
    background-color: black;
  }

  .configure-button:hover {
   background-color: white; /* Fondo verde al pasar el cursor */
    color: black; /* Texto blanco al pasar el cursor */
  }
  
  /* Estilos para el segundo popup de configuración de cookies */
  .config-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 450px;
    z-index: 1000;
  }
  
  .option {
    margin-bottom: 10px;
  }
  
  /* Estilos para las opciones de configuración */
  .config-option {
    display: flex;
    align-items: center;
  }
  
  .option-input {
    margin-right: 10px;
  }
  
  .hidden {
    display: none;
  }
  
  
  
  
  