.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #AAA; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666; 
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.appAside {
  width: 50%;
  background-color: #6b5b95;
}

.appForm {
  width: 50%;
  background-color: #12130f;
  padding: 25px 40px;
  overflow: auto;
}

.pageSwitcher {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10%;
}

.pageSwitcherItem {
  background-color: #40434e;
  color: #9da6b1;
  padding: 10px 25px;
  cursor: pointer;
  font-size: 0.9em;
  border: none;
  outline: none;
  display: inline-block;
  text-decoration: none;
}

.pageSwitcherItem-active {
  background-color: #6b5b95;
  color: white;
}

.pageSwitcherItem:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.pageSwitcherItem:last-child {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.formCenter {
  margin-top: 25vh;
  margin-left: 5vh;
  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.formCenterTop {
  margin-top: 10vh;
  margin-left: 5vh;
  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.mobformCenterTop {
  margin-top: 3vh;
  margin-left: 5vh;
  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.formCenterCreateAccount {
  margin-top: 10vh;
  margin-left: 5vh;
  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.mobformCenterCreateAccount {
  margin-top: -1vh;
  margin-left: 5vh;
  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.formTitle {
  color: #707c8b;
  font-weight: 300;
  margin-bottom: 50px;
}

.formTitleLink {
  color: #707c8b;
  text-decoration: none;
  display: inline-block;
  font-size: 1.7em;
  margin: 0 10px;
  padding-bottom: 5px;
}

.formTitleLink:first-child {
  margin-left: 0;
}

.formTitleLink-active {
  color: white;
  border-bottom: 1px solid #6b5b95;
}

.formField {
  margin-bottom: 40px;
  width: 65%;
}

.mobformField {
  margin-bottom: 40px;
  width: 90%;
}

.mobformFieldregister {
  margin-bottom: 20px;
  width: 90%;
}

.formField2 {
  margin-bottom: 40px;
  width: 65%;
  align-items: "center";
}

.formFields {
  margin-bottom: 40px;
  width: 100%;
}

.formField_nomargin {
  margin-bottom: 20px;
  margin-top:-10px;
  width: 75%;
}

.mobformField_nomargin {
  margin-bottom: 20px;
  margin-top:-10px;
  width: 90%;
}

.formFieldLabel {
  display: block;
  text-transform: uppercase;
  font-size: 0.9em;
  color: black;
}
.formFieldInput {
  width: 100%;
  background-color: transparent;
  border: none;
  /*color: gray;*/
  color: black;
  outline: none;
  border-bottom: 1px solid #445366;
  font-size: 1em;
  font-weight: 300;
  padding-bottom: 10px;
  margin-top: 10px;
}

.formFieldInput::placeholder {
  color: #616e7f;
  font-size: 0.9em;
}

.formFieldButton {
  background-color: black;
  color: white;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 10px;

  /*padding: 15px 15px;*/
  align-items: center;
  font-size: 1.05em;
  font-weight: 500;
  padding-top: 10px;
  text-align: center;
  justify-content: center;
  padding-bottom: 40px;
}

.formFieldFilledButton {
  background-color: black;
  color: white;
  height: 52px;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 10px;

  /*padding: 15px 15px;*/
  align-items: center;
  font-size: 1.05em;
  font-weight: 500;
  padding-top: 10px;
  text-align: center;
  justify-content: center;
  padding-bottom: 40px;
}

.mobformFieldFilledButton {
  background-color: black;
  color: white;
  height: 32px;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 6px;

  /*padding: 15px 15px;*/
  align-items: center;
  font-size: 0.90em;
  font-weight: 500;
  padding-top: 10px;
  text-align: center;
  justify-content: center;
  padding-bottom: 34px;
}

.mobformFieldOutlineButton {
  height: 30px;
  border: 3px solid black;
  background-color: white;
  border-radius: 6px;
  align-items: center;
  font-size: 0.95em;
  font-weight: 500;
  padding-top:6px;
  text-align: center;
  justify-content: center;
  padding-bottom: 32px;
}

.formFieldOutlineButton {
  height: 40px;
  border: 4px solid black;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  font-size: 1.05em;
  
  padding-top:9px;
  text-align: center;
  justify-content: center;
  padding-bottom: 36px;
}

.formFieldButton80 {
  background-color: black;
  width: 80%;
  color: white;
  border: none;
  outline: none;
  border-radius: 10px;
  margin-top: 3pc;

  /*padding: 15px 15px;*/
  align-items: center;
  font-size: 1.05em;
  font-weight: 500;
  padding-top: 10px;
  text-align: center;
  justify-content: center;
  padding-bottom: 40px;
}

/*html, img {
  filter: invert(1)
}*/

.div_hor_left{
  text-align: left;
  width: 50%;
  float: left;
}


.div_hor_right{
  text-align: right;
  float: left;
  width: 40%;
  float: right;
}

.formFieldLink {
  color: #66707d;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1.5px solid #6b5b95;
  padding-bottom: 5px;
}

.formFieldCheckboxLabel {
  color: #646f7d;
  font-size: 0.9em;
}

.formFieldCheckbox {
  position: relative;
  top: 1.5px;
}

.formFieldTermsLink {
  color: gray;
  text-decoration: none;
  font-weight:bold;
  display: inline-block;
  padding-bottom: 0px;
  margin-left: 0px;
}

.socialMediaButtons {
  padding: 10px 100px 10px 0px;
}


.verticalhorizontal {
  
    display: block;
    width: 40%;
    margin: 25%;
  
}

.mobAddAsset {
  height: 40px;
  width: 100%;
  border: 3px solid black;
  background-color: white;
  border-radius: 4px;/*8px;*/
  align-items: center;
  font-size: 0.95em;
  
  padding-top:0px;
  text-align: center;
  justify-content: center;
  padding-bottom: 4px;
}

.formLogInButton {
  height: 40px;
  border: 3px solid black;
  background-color: white;
  border-radius: 4px; /*8px;*/
  align-items: center;
  font-size: 0.65em;
  
  padding-top:0px;
  text-align: center;
  justify-content: center;
  padding-bottom: 4px;
}

.formWebAppButton {
  height: 40px;
  /*border: 3px solid black;*/
  /*border: 3px solid #FF9000;*/
  background-color: #DDDDDD;
  border-radius: 4px; /*8px;*/
  align-items: center;
  font-size: 0.9em;
  padding-top:0px;
  text-align: center;
  justify-content: center;
  padding-bottom: 0px;
  padding: 2px;
}

.formLogInButtonContent {
  height: 40px;
  border: 3px solid black;
  background-color: white;
  border-radius: 4px;/*8px;*/
  align-items: center;
  font-size: 0.95em;
  
  padding-top:0px;
  text-align: center;
  justify-content: center;
  padding-bottom: 4px;
}

.formSignInButtonContent {
  background-color: black;
  color: white;
  height: 40px;
  border-radius: 3px;
  align-items: center;
  font-size: 0.95em;
  padding-top: 0px;
  text-align: center;
  justify-content: center;
  padding-bottom: 4px;
}

.formSignInButton {
  background-color: black;
  color: white;
  height: 40px;
  border-radius: 3px;
  align-items: center;
  font-size: 0.65em;
  padding-top: 0px;
  text-align: center;
  justify-content: center;
  padding-bottom: 4px;
}

.runbacktest {
  background-color: black;
  color: white;
  height: 50px;
  border-radius: 3px;
  align-items: center;
  font-size: 0.65em;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  justify-content: center;
  
}


.formSignInButtonMobileFront {
  background-color: black;
  color: white;
  height: 40px;
  width: 100%;
  border-radius: 3px;
  align-items: center;
  
  font-size: 0.65em;
  padding-top: 0px;
  text-align: center;
  justify-content: center;
  padding-bottom: 4px;
}
.formSignInButtonMobile {
  background-color: black;
  color: white;
  height: 40px;
  width: 100%;
  border-radius: 3px;
  align-items: center;
  margin-top: 4em;
  font-size: 0.65em;
  padding-top: 0px;
  text-align: center;
  justify-content: center;
  padding-bottom: 4px;
}